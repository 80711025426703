import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import OralTest from './AppTest';
import AppAudioTest from './AppAudioTest';
import IsSupported from './IsSupported';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Redirect from "./Redirect"
ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/quran/:token/:parentId/:compType/:regId/record" element={<App />} />
      <Route path="/" element={<Redirect />} />
      <Route path="/oralTest/:token/:parentId/:compType/:regId/record" element={<OralTest />} />
      <Route path="/oralTestTest" element={<AppAudioTest />} />
      <Route path="/isSupported" element={<IsSupported />} />

     
    </Routes>
    
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
