import logo from './logo.svg';
import './App.css';
import React, {useState,useEffect} from 'react'
import VideoRecorder from 'react-video-recorder'
import DisconnectedView from './Disconnected';
import Actions from './defaults/render-actions';
import jwt_decode from "jwt-decode";
import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import { useSpeechSynthesis } from 'react-speech-kit';
import questions from './questions.json'
import { Navbar, Jumbotron, Button } from 'react-bootstrap';
import Countdown from 'react-countdown';

import {
  useParams,
} from "react-router-dom";
function App() {
  //const API = 'https://us-central1-ramadan-competition.cloudfunctions.net/ramadancompetition-v2-first'
  const API = '/api'
  // TODO: Replace the following with your app's Firebase project configuration
  const blob_url = process.env.REACT_APP_AZURE_BLOB_URL
  const azure_container = 'quran'
  const onEnd = () => {
    // You could do something here after speaking has finished
    console.log("speech end")
        
  };
  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
    onEnd,
  });
  const getChromeVersion =()=> {
    var pieces = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
    if (pieces == null || pieces.length != 5) {
        return undefined;
    }
    pieces = pieces.map(piece => parseInt(piece, 10));
    return {
        major: pieces[1],
        minor: pieces[2],
        build: pieces[3],
        patch: pieces[4]
    };
}
  
  const { token, parentId, compType, regId } = useParams();
  console.log({ token, parentId, compType, regId, chrome:getChromeVersion(), video: window.Modernizr })
  const [tokenValidated, setTokenVlidated] =  useState(false)
  const [tokenData, setTokenData] =  useState(null)
  const [testActive, setTestActive] =  useState(false)
  const [videoBlob, setVideoBlob] =  useState(null)
  const [recording, setRecording] =  useState(false)
  const [studentInfo, setStudentInfo] =  useState(null)
  const [studentQuestions, setStudentQuestions] =  useState(null)
  const [currentQuestion, setCurrentQuestion] =  useState(0)
  const [blob, setBlob] =  useState(null)
  const [uploading, setUploading] =  useState(null)
  const [uploadProgress, setUploadProgress] =  useState(0)
  const [blobUrl, setBlobUrl] =  useState(null)
  const [containerClient, setContainerClient] =  useState(null)
  const filename = `${parentId}-${compType}-${regId}.webm` //'file.webm'//
  const nextQuestion = ()=>{
    if(studentQuestions[currentQuestion]){
      let obj = {text:studentQuestions[currentQuestion]}
      console.log(obj)
      speak(obj)
      setCurrentQuestion(currentQuestion+1)
    }else{
        speak({text:"Oral test is complete, please press the stop button to submit your test."})
    }
  }
  const arabic = voices.filter(voice=>voice.lang.substr(0,2)==='ar')
  const english = voices.filter(voice=>voice.lang.substr(0,2)==='en')
  //const allVoices = {en:english, ar:arabic}
  console.log("Supported", {supported, voices,arabic, english})
  const replayQuestion = ()=>{
    if(studentQuestions[currentQuestion]){
      if(studentQuestions[currentQuestion-1]){
        let obj = {text:studentQuestions[currentQuestion-1]}
        console.log(obj)
        speak(obj)
      }
    }else{
      speak({text:"Oral test is complete, please press the stop button to submit your test."})
    }
      
  }
  const speakNow=(o, voice)=>{
    if(typeof o === 'string'){
      let obj = {text:o,voice:voice?voice:english[0]}
      speak(obj)
    }else{
      for(let a in o){
        o[a].text?speakNow(o[a].text, arabic[0]):speakNow(o[a])
      }
    }
  }
  const playQuestion = (group,i)=>{
    if(questions[group][i]){
      if(speaking)
        cancel()
      speakNow(questions[group][i] )
        
        
    }else{
      speak({text:"Oral test is complete, please press the stop button to submit your test."})
    }
      
  }


  
  const getQuestionText=(question)=>{
    return question.map(q=>q.text?q.text:q)

  }
  return (<div className="App" style={{height:'100vh'}}>
      <div>Supports Camera: {window.Modernizr.getusermedia?"YES":"NO"}</div>
      <div>Supports Test: {supported && arabic.length>0 && english.length>0?"YES":"NO"}</div>
      {supported && arabic.length>0 && english.length>0?null:<div>To get support for test follow instructions  <a href="https://support.microsoft.com/en-us/topic/how-to-download-text-to-speech-languages-for-windows-10-d5a6b612-b3ae-423f-afa5-4f6caf1ec5d3#:~:text=Select%20the%20Start%20button%2C%20then,you%20want%20from%20the%20list">here</a>.</div>}
    </div>
  );
}

export default App;
