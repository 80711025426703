import logo from './logo.svg';
import './App.css';
import React, {useState,useEffect} from 'react'
import VideoRecorder from 'react-video-recorder'
import DisconnectedView from './Disconnected';
import Actions from './defaults/render-actions';
import jwt_decode from "jwt-decode";
import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import { useSpeechSynthesis } from 'react-speech-kit';
import questions from './questions.json'
import { Navbar, Jumbotron, Button } from 'react-bootstrap';
import Countdown from 'react-countdown';

import {
  useParams,
} from "react-router-dom";
function App() {
  //const API = 'https://us-central1-ramadan-competition.cloudfunctions.net/ramadancompetition-v2-first'
  const API = '/api'
  // TODO: Replace the following with your app's Firebase project configuration
  const blob_url = process.env.REACT_APP_AZURE_BLOB_URL
  const azure_container = 'quran'
  const maxUpload = 2
  const onEnd = () => {
    // You could do something here after speaking has finished
    console.log("speech end")
        
  };
  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
    onEnd,
  });
  const arabic = voices.filter(voice=>voice.lang.substr(0,2)==='ar')
  const english = voices.filter(voice=>voice.lang.substr(0,2)==='en')
  console.log("Supported", supported)
  const { token, parentId, compType, regId } = useParams();
  console.log({ token, parentId, compType, regId })
  const [tokenValidated, setTokenVlidated] =  useState(false)
  const [tokenData, setTokenData] =  useState(null)
  const [testActive, setTestActive] =  useState(false)
  const [videoBlob, setVideoBlob] =  useState(null)
  const [recording, setRecording] =  useState(false)
  const [studentInfo, setStudentInfo] =  useState(null)
  const [studentQuestions, setStudentQuestions] =  useState(null)
  const [currentQuestion, setCurrentQuestion] =  useState(0)
  const [blob, setBlob] =  useState(null)
  const [uploading, setUploading] =  useState(false)
  const [uploadProgress, setUploadProgress] =  useState(0)
  const [blobUrl, setBlobUrl] =  useState(null)
  const [nextUploadCount, setNextUploadCount] =  useState(0)
  const [containerClient, setContainerClient] =  useState(null)
  const filename = `${parentId}-${compType}-${regId}.webm` //'file.webm'//
  const nextQuestion = ()=>{
    if(studentQuestions[currentQuestion]){
      speakNow(studentQuestions[currentQuestion])
      setCurrentQuestion(currentQuestion+1)
    }else{
        speak({text:"Oral test is complete, please press the stop button to submit your test."})
    }
  }
  const replayQuestion = ()=>{
    if(studentQuestions[currentQuestion]){
      if(studentQuestions[currentQuestion-1]){
        speakNow(studentQuestions[currentQuestion-1])
      }
    }else{
      speak({text:"Oral test is complete, please press the stop button to submit your test."})
    }
      
  }

  const speakNow=(o, voice)=>{
    if(typeof o === 'string'){
      let obj = {text:o,voice:voice?voice:english[0]}
      speak(obj)
    }else{
      for(let a in o){
        o[a].text?speakNow(o[a].text, arabic[0]):speakNow(o[a])
      }
    }
  }
  const getBlobsInContainer = async (containerClient) => {
  
    const returnedBlobUrls = [];
    let found = null
    let foundCount = 0
    for await (const blob of containerClient.listBlobsFlat()) {
      for(let i=0;i<maxUpload;i++){
        const c = i>0?('-'+i):''
        if(blob.name === `${parentId}-${compType}-${regId}${c}.webm`){
          found = blob
          foundCount++
        }
      }
      
      //returnedBlobUrls.push(blob);
    }
    console.log('foundCount: ', foundCount)
    return foundCount;
  }
  const newRecording = ()=>{
    
    setBlob(false)
  }
  const uploadVideo = async (videoBlob)=>{
    setVideoBlob(videoBlob)
    console.log('videoBlob', videoBlob)
    const blobClient = containerClient.getBlockBlobClient(`${parentId}-${compType}-${regId}${nextUploadCount>0?'-'+nextUploadCount:''}.webm`);
    
    const options = { blobHTTPHeaders: { blobContentType: videoBlob.type }, onProgress: (e)=>{
      console.log("onProgress", e.loadedBytes, videoBlob.size)
      const done = e.loadedBytes/videoBlob.size
      if(done === 1){
        setUploading(false)
        setBlob(true)
        setNextUploadCount(nextUploadCount+1)
      }
      setUploadProgress(done*100)
    }};
    setUploading(true)
    await blobClient.uploadData(videoBlob, options)
  }
  useEffect(()=>{
    async function temp(){
    if(blobUrl){
      console.log('blobUrl', blobUrl)
      const blobService = new BlobServiceClient(
        blobUrl
      );
      const cc = blobService.getContainerClient(compType.toLowerCase())
      setContainerClient(cc)
      await cc.createIfNotExists({
        access: 'container',
      })
      if(!blob){
        const d = await getBlobsInContainer(cc)
        setBlob(d>0)
        setNextUploadCount(d)
      }
    }}
    temp()
    
  }, [blobUrl])

  
  useEffect(()=>{
    const path = `/token/${token}/${parentId}/${compType}/${regId}/validate`
      console.log(API+path)
    if(!tokenValidated){
      
      fetch(API+path)
      .then(res=>res.json())
      .then(async json=>{
        if(json.bloburl){
          
          var td = jwt_decode(token, { header: false });
          setTokenData(td)
          setTokenVlidated(true)
          setBlobUrl(json.bloburl)
          setTestActive(json.testDate.started)
          //setTestActive(true)
          setStudentInfo(json)
          setStudentQuestions(questions[json.group])
          console.log({td, json, q: questions[json.group]})
        }
      })
      /*fetch("https://www.googleapis.com/robot/v1/metadata/x509/securetoken@system.gserviceaccount.com")
      .then(res=>res.json())
      .then(async json=>{
        console.log(json)
        var header = jwt_decode(token, { header: true });
        var kid = header.kid
        console.log(header, json[kid])

        
        jwtVerify(token, json[kid])
        .then((payload, protectedHeader)=>{
          console.log(payload, protectedHeader)
        })
      })*/
      
    }
  }, [tokenValidated])
  const doneMessage = maxUpload-nextUploadCount>0?<h2>Jazakallah Khair for submitted your recording, to submit a replacement click <a onClick={newRecording} style={{textDecoration:'underline', cursor:'pointer'}}>here</a>.  You have {maxUpload-nextUploadCount} tries left.</h2>:<h2>Jazakallah Khair for submitted your recording.</h2>
  return (
    <div className="App" style={{height:'100vh'}}>
      {!supported?<div>Your browser is not supported for this test, please use latest Chrome to partake in this oral test.</div>:tokenData&&testActive?!blob?uploading?<h2>uploading video, please wait and do not close this page untill upload is complete.  <br/>{uploadProgress}% Completed.</h2>:<div style={{height:'100vh'}}><VideoRecorder 
        chunkSize={250}
        constraints={{
          audio: true,
          video: true
        }}
        countdownTime={3000}
        dataAvailableTimeout={500}
        timeLimit={15*60*1000}
        isFlipped
        mimeType={undefined}
        showReplayControls
        isOnInitially
        renderDisconnectedView={()=><DisconnectedView/>}
        renderActions={Actions}
        onCameraOn={()=>{
            console.log("onCameraOn")
            speakNow([{text:"ٱلسَّلَامُ عَلَيْكُمْ"},"welcome to Ramadan Competition 2022, oral test.  When you are ready to begin, please press the record button below.  Once you begin you cannot start again.  When you are done please press stop, and wait for the upload to complete."])
            //speak({text:"Asalaamu Aalaykum, welcome to Ramadan Competition 2022, oral test.  When you are ready to begin, please press the record button below.  Once you begin you cannot start again.  When you are done please press stop, and wait for the upload to complete."})
        }}
        onStartRecording={()=>{
            setRecording(true)
            if(speaking){
                cancel()
            }
            nextQuestion()
        }}
        onRecordingComplete={async videoBlob => {
          // Do something with the video...
          
          uploadVideo(videoBlob)
         
        }}
      />{recording?<><Button disabled={speaking} size="lg" className="btn posl" onClick={()=>replayQuestion()}><i className="fa fa-undo"></i> Replay Question</Button><Button disabled={speaking} size="lg" className="btn pos" onClick={()=>nextQuestion()}>Next Question <i className="fa fa-step-forward"></i></Button></>:null}</div>:<h2>Jazakallah Khair for submitted your recording, to submit a replacement send an email request to mail@muslimville.com with your registration id {compType}{regId}</h2>:studentInfo?<div>Oral test will begin in <Countdown date={studentInfo.testDate.start} /></div>:<div>loading ...</div>}
    </div>
  );
}

export default App;
